import React, {useState} from 'react'
import {IQuestion} from '../../../types'
import AntFileUpload from './antFileUpload'
import FileUploadValidationScreen from './fileUploadValidationScreen'
interface IProps {
    question:IQuestion
    setNewQuestion:(title: string, newQuestion: IQuestion) => void
    title:string
}

export interface IFileSizeValidationMetadata {
    isExceeding:boolean
    totalUploaded:number
    totalBeingUploaded:number
    totalExceeding:number
}

export interface IFileTypeValidation {
    isNotValid:boolean
    invalidType:string
}

export interface IFileNameValidation {
    isNotValid:boolean
    invalidName:string
}

interface IAntFiles {
    uid: string
    lastModified: number
    lastModifiedDate: string
    name: string
    size: number
    type: string
    percent: number
    originFileObj: File
  }
export const getSize = (size:number, type:string):string => {
    switch(type){
        case "kb": return  `${Math.round(((size/1024) + Number.EPSILON) * 100) / 100} KBs`
        case "mb": return  `${Math.round(((size/1024/1024) + Number.EPSILON) * 100) / 100} MBs`
        default : return ""
    }
}
const AttachmentQuestion = (props:IProps):JSX.Element => {

    const [fileSizeErrorData, setFileSizeErrorData] = useState<IFileSizeValidationMetadata>({isExceeding:false, totalBeingUploaded:0, totalUploaded:0, totalExceeding:0})
    const [fileTypeErrorData, setFileTypeErrorData] = useState<IFileTypeValidation>({isNotValid:false, invalidType:""})
    const [fileNameErrorData, setFileNameErrorData] = useState<IFileNameValidation>({isNotValid:false, invalidName:""})
    const {question, setNewQuestion, title} = props   
    const fileSizeLimit = 8000000

    const validateAndUpdateFileSize = (files:File[]):boolean => {
        const previouslyUploadedFileSize = fileSizeErrorData.totalUploaded
        let totalUploadSize = 0
        for(let file of files){
            totalUploadSize += file.size
        }
        const totalBeingUploaded = totalUploadSize - previouslyUploadedFileSize
        const totalExceeding = totalUploadSize - fileSizeLimit
        if(totalExceeding > 10000){
            setFileSizeErrorData({...fileSizeErrorData, isExceeding:true, totalBeingUploaded, totalExceeding})
            return false
        }else{
            setFileSizeErrorData({...fileSizeErrorData, isExceeding:false, totalUploaded:totalUploadSize})
            return true
        }
    }

    const addFilesToQuestion = (newFiles:IAntFiles[]) => {
        const newFilesArray:File[] = newFiles.map(antFileObj => antFileObj.originFileObj )
        const newQuestions:IQuestion = ({...question, Files:newFilesArray})
        setNewQuestion(title, newQuestions)
    }

    const validateFileDuplicateName = (files:File[]):boolean => {
        const fileNameArray = files.map(function(file){ return file.name });
        const hasDuplicateFileName = fileNameArray.some((fileName, idx) => { 
            const hasDuplicate = fileNameArray.indexOf(fileName) !== idx 
            if(hasDuplicate){
                setFileNameErrorData({invalidName:fileName, isNotValid:true})
            }
            return hasDuplicate
        });
        
        return !hasDuplicateFileName
    } 

    const validateFileType = (files:File[]):boolean => {
        const validFileTypes = [
            "jpeg", 
            "png", 
            "pdf", 
            // "doc", 
            // "docx", 
            // "msword", 
            // "vnd.openxmlformats-officedocument.wordprocessingml.document",
            // "openxmlformats-officedocument.wordprocessingml.document",
            // "vnd.openxmlformats-officedocument.wordprocessingml.template"
        ]

        let isValidData:IFileTypeValidation = {isNotValid:false,invalidType:""}
        for(let file of files){
            const fileType = file.type.split("/")[1]
            if(validFileTypes.includes(fileType)){
                isValidData.isNotValid = false
                isValidData.invalidType = ""
            }else{
                isValidData.isNotValid = true
                isValidData.invalidType = fileType
            }
        }
        if(isValidData.isNotValid){
            setFileTypeErrorData(isValidData)
            return false
        }
        return true
    }
    const validateFile = (files:File[]):boolean => {
        const isTypeValid = validateFileType(files)
        const isNameValid = validateFileDuplicateName(files)
        const isSizeValid = isTypeValid && isNameValid && validateAndUpdateFileSize(files)
        return isTypeValid && isSizeValid && isNameValid
    }
    return (
        <>
            <p style={{textAlign:"left",margin:"0px 0px 0px 1rem", }}>Total <b>file upload limit </b>is 8MBs. {`Total uploaded ${getSize(fileSizeErrorData.totalUploaded, "kb")} or ${getSize(fileSizeErrorData.totalUploaded, "mb")}`}</p>
            <p style={{textAlign:"left",margin:"0px 0px 0px 1rem", }}><b>Allowed</b> file upload types are <b>jpeg</b>, <b>png</b> and <b>pdf</b></p>
            <p style={{textAlign:"left",margin:"0px 0px 1rem 1rem", }}><b>Aviod</b> special characters in file name <b>Example: ( "'!@#$%^&*(){}[]:";', )</b></p>
            <AntFileUpload 
                addFilesToQuestion={addFilesToQuestion} 
                validateFile={validateFile}         
            />
            {fileSizeErrorData.isExceeding || fileTypeErrorData.isNotValid || fileNameErrorData.isNotValid ? <FileUploadValidationScreen 
                fileSizeErrorData={fileSizeErrorData}
                setFileSizeErrorData={setFileSizeErrorData}
                fileTypeErrorData={fileTypeErrorData}
                setFileTypeErrorData={setFileTypeErrorData}
                fileNameErrorData={fileNameErrorData}
                setFileNameErrorData={setFileNameErrorData}
            />: null}
        </>
    )
}

export default AttachmentQuestion