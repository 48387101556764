import React, {useEffect, useState} from 'react';
import Tick from './animatedTick';
import { ISubmitData, ISubmitStatus } from '../../types';
import { submitFilesQuestion, submitNormalQuestion} from '../../utils'
import { questionType } from '../../constants'
import {AxiosResponse} from 'axios'
import { Spin } from 'antd';
import {styles as globalStyle} from '../styles'
interface IProps {
  submitStatus:ISubmitStatus
  setChunkSubmitError:(errorData: any, chunk?: ISubmitData) => void
}

const SurveySubmitHandlingComponenet = (props:IProps):JSX.Element => {
  const [surveyChunkBeingSubmitted, setSurveyChunkBeingSubmitted] = useState<ISubmitData | null>(null)
  const [chunksYetToBeSubmitted, setChunksYetToBeSubmitted] = useState<ISubmitData[] | []>([])

  useEffect(()=>{

    const updateStateWithNewChunk = (respose:AxiosResponse, surveyChunkBeingSubmitted:ISubmitData) => {
      console.log(respose)
      const chunksWithCurrentChunkRemoved = chunksYetToBeSubmitted.filter(chunk =>{ 
        return !(chunk.QuestionResponse.QuestionId === surveyChunkBeingSubmitted.QuestionResponse.QuestionId)
      })
      setChunksYetToBeSubmitted(chunksWithCurrentChunkRemoved)
      chunksWithCurrentChunkRemoved.length && setSurveyChunkBeingSubmitted(chunksWithCurrentChunkRemoved[0])
    }
    try {
      if(!surveyChunkBeingSubmitted){
        setChunksYetToBeSubmitted(props.submitStatus.surveyChunks)
        setSurveyChunkBeingSubmitted(props.submitStatus.surveyChunks[0])
      }
    }catch(error){
      props.setChunkSubmitError(error)
    }

    try{
      if(surveyChunkBeingSubmitted && props.submitStatus.newSurveyResponseId && chunksYetToBeSubmitted.length){
        if(surveyChunkBeingSubmitted.QuestionResponse.Type === questionType.Attachments){
          submitFilesQuestion(surveyChunkBeingSubmitted, props.submitStatus.newSurveyResponseId)?.then(res => {
            updateStateWithNewChunk(res, surveyChunkBeingSubmitted)
          }).catch((error => {
            surveyChunkBeingSubmitted && props.setChunkSubmitError(error,surveyChunkBeingSubmitted) 
          }))
        }else{
          submitNormalQuestion(surveyChunkBeingSubmitted, props.submitStatus.newSurveyResponseId).then(res => {
            updateStateWithNewChunk(res, surveyChunkBeingSubmitted)
          }).catch((error => {
            surveyChunkBeingSubmitted && props.setChunkSubmitError(error,surveyChunkBeingSubmitted)
          }))
        }
      }
    }catch(error){
      surveyChunkBeingSubmitted && props.setChunkSubmitError(error,surveyChunkBeingSubmitted)
    }
  
  },[surveyChunkBeingSubmitted?.QuestionResponse.QuestionId])

  return (
    // @ts-ignore
    chunksYetToBeSubmitted.length ? <div style={globalStyle.translusentScreenStyle.outterBox}>
      { // @ts-ignore
        <div  style={globalStyle.translusentScreenStyle.innerBox}>
          <div  style={styles.lastWrapper}> 
          {chunksYetToBeSubmitted.length ?
            // @ts-ignore
            <div style={styles.box}>
              {
                <>
                  <p style={styles.heading}>{surveyChunkBeingSubmitted?.QuestionResponse.QuestionGroupName}</p>
                  {// @ts-ignore
                    <p style={styles.taskHeading}>Below Question is being submitted:</p>
                  }
                  <>
                {   // @ts-ignore
                <div style={styles.questionNameBox}>
                      <Spin size="large" />
                      {
                        // @ts-ignore
                        <p style={styles.questionName}>{surveyChunkBeingSubmitted?.QuestionResponse.QuestionName}</p>
                      }
                    </div>}
                  </>
                </>
              }
            </div>: <Tick size={100} />
          }
          </div>
        </div>
      }
    </div>: <Tick size={100} />
  );
};

export default SurveySubmitHandlingComponenet

const styles = {
  wrapper: {
    background: "rgba(255,255,255,0.9)",
    width: "100%",
    top: "0",
    height: "120%",
    position: "absolute"
  },
  subWrapper: {
    marginTop: "25rem",
    width: "100%",
    position:"fixed"
  },
  lastWrapper: { maxWidth: "37rem", margin: "auto", marginTop:"-5rem" },
  box:{
    background: "white",
    boxShadow: "0 4px 8px 0 rgb(0 0 0 / 20%), 0 6px 20px 0 rgb(0 0 0 / 19%)",
    padding: "0 1rem 1rem 1rem",
    textAlign: "left"
  },
  heading:{
    display: "flex",
    background: "rgb(0, 160, 224)",
    margin: "0 -1rem",
    height: "3rem",
    paddingLeft: "24px",
    alignItems: "center",
    color: "white",
    fontSize: "16px",
    fontWeight: "bold"
  },
  taskHeading: { display: "block", marginTop: "1rem", textAlign: "center" },
  questionName: {
    fontSize: "20px",
    display: "block",
    marginTop: "1rem",
    textAlign: "center"
  },
  questionNameBox: {
    marginTop: "1rem",
    display: "flex",
    paddingLeft: "1rem",
    paddingTop: "1rem",
    justifyContent: "flex-start",
    flexDirection: "column"
  }
}
