import  React ,{Dispatch, useState, useEffect} from 'react';
import { DefaultButton, PrimaryButton } from '@fluentui/react/lib/Button';
import { Panel, PanelType } from '@fluentui/react/lib/Panel';
import {IPanelData,IProduct} from '../../types'
import NewProjectForm from './newProjectForm';
import {fieldTypes, gridColumns} from '../../constants'
const buttonStyles = { root: { marginRight: 8 } };

interface IProps {
  panelData:IPanelData
  setPanelData:Dispatch<IPanelData>
  addProductResponseQuestion:(product: IProduct) => void
  updateProductResponseQuestion:(product: IProduct) => void
  title:string
}

interface IProductgridData{
  [key:string ]: {
    name:string
    required:boolean
  }
}

const AddProjectPanel = (props:IProps):JSX.Element => {
  const initialPanelData:IPanelData = {isOpen: false, question:{Id:"",SubTitle:"",Title:"", Type:""}}
  const initialProduct:IProduct= {
    id:"",
    productName:"",
    upc:"",
    location:{key:"",text:""},
    states_where_manufactured:"",
    purpose:"",
    product_type:{key:"",text:""},
    product_category:{key:"",text:""},
    testing_performed:{key:"",text:""},
    chemical_substance:"",
    ppm:"",
  }

  const [newProduct, setNewProduct] = useState<IProduct>(initialProduct)
  const [editMode, setEditMode] = useState<boolean>(false)
  const [validationErrors, setValidationErrors] = useState<string[]>([])
  
  const {
    panelData,
    addProductResponseQuestion,
    updateProductResponseQuestion,
    setPanelData,
    title
  } = props

  
  // This panel doesn't actually save anything; the buttons are just an example of what
  // someone might want to render in a panel footer.
  const closePanel = () => {
    setValidationErrors([])
    setPanelData(initialPanelData)
    setNewProduct(initialProduct)
    if(editMode){
      setEditMode(false)
    }
  }

  const generateID = function(){
    return Date.now().toString(36) + Math.random().toString(36);
  }

  const validateForm = ():string[] => {
    let blackFields:string[] = []
    let productGridMetaData:IProductgridData = {}
    gridColumns.forEach(item =>{
      productGridMetaData[item.key] = {name:item.name, required:item.required || false}
    })
    productGridMetaData = {...productGridMetaData, id:{name:"id", required:false}}
    const checkIfFieldIsBlank = (field:string) =>{
      // @ts-ignore
      const fieldType = typeof newProduct[field]
      if(fieldType === "object"){
        // @ts-ignore
        return !!newProduct[field].key
      }
      if(fieldType === "string"){
        // @ts-ignore
        return !!newProduct[field]
      }
      return false
    }
    Object.keys(newProduct).forEach(field => {
      const isFieldRequired = productGridMetaData[field].required
      const isFieldNotBlank = checkIfFieldIsBlank(field)
      const fieldName = productGridMetaData[field].name
      if(isFieldRequired && !isFieldNotBlank){
        blackFields.push(fieldName)
      }
    })
    
    return blackFields
  }

  const onSaveClick = ()=>{
    const blankFields = validateForm()
    if(blankFields.length === 0){
      if(!panelData.product){
        addProductResponseQuestion({...newProduct, id:generateID()})
      }
  
      if(editMode){
        updateProductResponseQuestion(newProduct)
        setEditMode(false)
      }
      setPanelData(initialPanelData)
      setNewProduct(initialProduct)
    }else{
      setValidationErrors(blankFields)
    }
  }

  const onRenderFooterContent = () => (
      <div>
        <PrimaryButton onClick={onSaveClick} styles={buttonStyles}>
          Save
        </PrimaryButton>
        <DefaultButton onClick={closePanel}>Cancel</DefaultButton>
      </div>
    )

  useEffect(()=>{
    
    
    if(!!panelData.product && !editMode){
      setNewProduct(panelData.product)
      setEditMode(true)
    }
  },[panelData.product?.id])

  return (
    <div>
      
      <Panel
        isOpen={panelData.isOpen}
        onDismiss={closePanel}
        headerText={title}
        closeButtonAriaLabel="Close"
        onRenderFooterContent={onRenderFooterContent}
        // Stretch panel content to fill the available height so the footer is positioned
        // at the bottom of the pageks
        type={PanelType.custom}
        customWidth={'50rem'}
        isFooterAtBottom={true}
      >
      <NewProjectForm 
         editMode={editMode}
         panelData={panelData}
         setPanelData={setPanelData}
         addProductResponseQuestion={addProductResponseQuestion}
         newProduct={newProduct}
         setNewProduct={setNewProduct}
         validationErrors={validationErrors}
         setValidationErrors={setValidationErrors}
      />
      </Panel>
    </div>
  );
};

export default AddProjectPanel
