import React, {Dispatch, SetStateAction} from 'react';
import { Button } from 'antd';
import { IResponseValidationData } from '../../types';
import {styles as globalStyle} from '../styles'

interface IProps {
    surveyDataValidation:IResponseValidationData
    setSurveyDataValidation:Dispatch<SetStateAction<IResponseValidationData>>
}

const SurveyDataValidationScreen = (props:IProps):JSX.Element => {
  
    const {surveyDataValidation, setSurveyDataValidation} = props

    const resetSurveyDataUpload = () => {
        setSurveyDataValidation({questions:[], hasInvalidQuestions:false})
    }
  return (
    // @ts-ignore
    <div style={globalStyle.translusentScreenStyle.outterBox}>
      { // @ts-ignore
        <div  style={globalStyle.translusentScreenStyle.innerBox}>
          <div  style={styles.lastWrapper}> 
          {
            // @ts-ignore
            <div style={styles.box}>
              {
                <>
                  {// @ts-ignore
                      <p style={styles.taskHeading}>{`The following questions require a response. Please resubmit after providing the response.`}</p>
                  }
                <>
                {   // @ts-ignore
                  <div style={styles.questionNameBox}>
                      {surveyDataValidation.questions.map(question => {
                          return <p><span style={{fontWeight:600}}>{question.GroupName}: </span> {question.Title}</p>
                      })}
                      <div style={styles.buttonDiv}>
                          <Button type="primary" danger ghost
                          onClick={resetSurveyDataUpload}
                          >
                              Cancel Survey Submit
                          </Button>
                      </div>
                    </div>}
                  </>
                </> 
              }
            </div>
          }
          </div>
      </div>}
    </div>
  );
};

export default SurveyDataValidationScreen

const styles = {
  wrapper: {
    background: "rgba(255,255,255,0.9)",
    width: "100%",
    top: "0",
    left: "0",
    height: "120%",
    position: "absolute",
    zIndex:"200"
  },
  subWrapper: {
    marginTop: "16rem",
    width: "100%",
    position:"fixed"
  },
  lastWrapper: { maxWidth: "37rem", margin: "auto", marginTop:"-5rem 0rem 0rem 0rem" },
  box:{
    background: "white",
    boxShadow: "0 4px 8px 0 rgb(0 0 0 / 20%), 0 6px 20px 0 rgb(0 0 0 / 19%)",
    padding: "0 1rem 1rem 1rem",
    textAlign: "left"
  },
  heading:{
    display: "flex",
    // background: "rgb(0, 160, 224)",
    margin: "0 -1rem",
    height: "3rem",
    paddingLeft: "24px",
    alignItems: "center",
    color: "white",
    fontSize: "16px",
    fontWeight: "bold"
  },
  taskHeading: {
    display: "block",
    textAlign: "center",
    margin: "0px -1rem",
    padding: "18px 0px",
    fontSize: "17px",
    fontWeight: 600
  },
  messageName: {
    margin:"auto"
  },
  questionNameBox: {
    // marginTop: "1rem",
    display: "flex",
    // paddingLeft: "1rem",
    // paddingTop: "1rem",
    justifyContent: "flex-start",
    flexDirection: "column",
    padding:"4px 2rem"
  },
  buttonDiv:{
    display: "flex",
    justifyContent: "center",
    marginTop: "1rem",
    padding:"0"
  }
}
