import React from 'react'
import { Button } from 'antd';
import { ISurveyData } from '../../types';

interface IProps {
  isBeingSubmitted:boolean
  onSubmitClick:() => void
  surveyData:ISurveyData | undefined | null
}

const SubmitButton = (props:IProps) => {

  const submitData = async (surveyData:ISurveyData) => {
    props.onSubmitClick()
  }

  return (
    <>
    {// @ts-ignore
    <div style={styles.wrapper}>
      <Button type="primary" style={styles.button}
        onClick={()=>
         props.surveyData && submitData(props.surveyData)
        }
      >Submit Survey</Button>
    </div>}
    </>
  )
}

export default SubmitButton

const styles = {
  wrapper:{
    width: "100%",
    display: "flex",
    justifyContent: "flex-end",
    position: "relative",
    bottom: "89px",
  },
  button:{ marginRight: "5rem", width: "14rem", height: "54px" }
}