import * as React from 'react';
import { Rating, RatingSize } from '@fluentui/react';


export const RatingInput: React.FunctionComponent = () => {
  return (
    <div>
      Large stars:
      <Rating
        max={5}
        size={RatingSize.Large}
        defaultRating={1}
        ariaLabel="Large stars"
        ariaLabelFormat="{0} of {1} stars"
      />
      
    </div>
  );
};
