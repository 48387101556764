
import React,{Dispatch, SetStateAction, useEffect} from 'react'
import { Dropdown, DropdownMenuItemType, IDropdownOption, IDropdownStyles } from '@fluentui/react/lib/Dropdown';
import { ChoiceGroup, IChoiceGroupOption } from '@fluentui/react/lib/ChoiceGroup';
import { ComboBox, IComboBoxOption, IComboBoxStyles } from '@fluentui/react/lib/ComboBox';

import { IStackTokens, Stack } from '@fluentui/react/lib/Stack';
import {IGridColumn, IProduct} from '../../types'


interface IProps {
  field:IGridColumn
  type?:string
  product?:IProduct
  newProduct?:IProduct
  editMode:boolean
  setNewProduct:Dispatch<SetStateAction<IProduct>>
}

const stackTokens: IStackTokens = { childrenGap: 20 };



export const ChoiceInputOption = (props:IProps) => {
  
  const {
    type,
    field,
    product,
    setNewProduct,
    newProduct
  } = props
  
  const options: IChoiceGroupOption[] | undefined = field?.options?.map(option => ({key:option.key, text:option.text}))
  // @ts-ignore 
  // const [selectedKey, setSelectedKey] = React.useState<string | undefined>(newProduct[field.key]);
  // 
  // @ts-ignore
  
   
  const onChange = (event: any, newValue?:IChoiceGroupOption) => {
    
    newProduct && setNewProduct({...newProduct, [field.key]:newValue})
    // setSelectedKey(newValue?.key)
  }

  // useEffect(()=>{
  //   
  //   // @ts-ignore
  //   setSelectedKey(newProduct[field.key])
  // },[newProduct?.id])
  // @ts-ignore
  // 
  // // @ts-ignore
  // 
  // 
  
  
  return (
    options ? <Stack tokens={stackTokens}>
      <ChoiceGroup
          placeholder={field?.defaultSelection}
          label={field.name}
          options={[...options]}
          onChange={onChange}
          // @ts-ignore
          selectedKey={newProduct && newProduct[field.key].key}
          required={field.required}
        /> 
        </Stack>
      : <h2>Issue with drop down - can't see options</h2>
  )
}
