
interface IProps {
    size :number
}

const Tick = (props:IProps) => (
  // @ts-ignore
    <div className="outerBox" style={styles.outterBox}>
      <div className="innerBox" style={styles.innerBox}>
        <div style={styles.thankYoutext}>Thank You !</div>
        <div
          style={styles.wrapper}
        >
          <style>
            {`
                .circular-chart {
                display: inline-block;
                margin: 10px auto;
                max-width: 80%;
                max-height: 250px;
              }
                .circle {
                stroke: green;
                fill: none;
                stroke-width: 3.8;
                stroke-linecap: round;
                animation: progress 1s ease-out ${true ? "forwards" : "infinite"};
                // transform-origin: center;
              }
                @keyframes progress {
                0% {
                  stroke-dasharray: 0, 100;
              }
              }
                .tick {
                fill: none;
                stroke: green;
                stroke-width: 3.8px;
                stroke-linecap: round;
                /* Stroke-dasharray property */
                stroke-dasharray: 50px;
                stroke-dashoffset: 50px;
                animation: move 0.3s ease-out forwards;
                -webkit-animation-delay: 0.3s;
                -moz-animation-delay: 0.3s;
                -o-animation-delay: 0.3s;
                animation-delay: 0.3s;
              }
                @keyframes move {
                100% {
                  stroke-dashoffset: 0;
              }
              }
            `}
          </style>
          <svg
            className="checkmark"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 52 52"
          >
            <circle className="checkmark__circle" cx="26" cy="26" r="25" fill="none" />
            <path
              className="checkmark__check"
              fill="none"
              d="M14.1 27.2l7.1 7.2 16.7-16.8"
            />
          </svg>
            <p style={{margin:0, marginLeft:"1rem"}}>Survey Submitted Successfully</p>
        </div>
      <div style={styles.closePageText}>Please to close this page</div>
      </div>
    </div>
  );
  
  export default Tick


  const styles = {
    outterBox:{
      width: "100vw",
      height: "100vh",
      display: "flex",
      background: "white",
      justifyContent: "center",
      alignItems: "center",
      position: "fixed",
      top: "0"
    },
    innerBox:{
      boxShadow: "rgb(0 0 0 / 24%) 0px 3px 8px",
      padding: "5rem 9rem"
    },
    wrapper:{
        height: "4rem",
        margin: "auto",
        display: "flex",
        alignItems: "center",
        placeContent: "center space-between",
        paddingTop: "1rem",
        fontSize: "1.5rem"
      },
    thankYoutext:{ fontSize: "3rem", marginLeft: "1rem" },
    closePageText:{
      fontSize: "1.25rem",
      marginTop: "2rem",
      marginLeft: "1rem"
    }
  }