import {IGridColumn} from "../types"
export const fieldTypes = {
   dropdownSingle:"single-select Dropdown",
   textMultiline:"Multiple lines of text",
   textSingleline:"single line of text"
}
export const questionType = {
   ProductGrid:"ProductGrid",
   TextMultiline:"Text",
   Attachments:"Attachment",
   ChoiceSingleSelect:"Choice"
} 

export const gridColumns:IGridColumn[] = [
   { 
      name: "Product Name", 
      key:"productName", 
      type:fieldTypes.textSingleline,
      required:true,
      placeHolder:"Product Name"
   },
   { 
      name:"UPC", 
      key:"upc", 
      type:fieldTypes.textSingleline,
      required:true,
      placeHolder:"UPC"
   },
   { 
      name:"Chemical location", 
      defaultSelection:"Select an option",
      key:"location", 
      type:fieldTypes.dropdownSingle,
      required:true,
      options:[
         {key:"947990000", text:"Product"},
         {key:"947990001", text:"Packaging"},
         {key:"947990002", text:"Both"}
      ]
   },
   { 
      name:"State(s) where Manufactured", 
      key:"states_where_manufactured", 
      type:fieldTypes.textSingleline,
      required:true,
      placeHolder:"State(s) where Manufactured"
   },
   { 
      name:"Purpose of Chemical", 
      key:"purpose", 
      type:fieldTypes.textMultiline,
      required:true,
      placeHolder:"Describe the purpose"
   },
   { 
      name:"Select the best option below to describe the product and the chemical present", 
      gridName:"Product Type",
      defaultSelection:"Select an option",
      key:"product_type", 
      type:fieldTypes.dropdownSingle,
      required:true,
      options:[
         {key:"947990000", text:"PFAS: Food Packaging"},
         {key:"947990001", text:"PFAS: Cosmetics"},
         {key:"947990002", text:"PFAS: Fabric Treatment"},
         {key:"947990003", text:"PFAS: Cookware"},
         {key:"947990004", text:"PFAS: Other"},
         {key:"947990005", text:"1,4Dioxane: Cosmetics"},
         {key:"947990006", text:"1,4Dioxane: Personal Care"},
         {key:"947990007", text:"1,4Dioxane: Household Cleaning"},
         {key:"947990008", text:"1,4Dioxane: Other"},
         {key:"947990009", text:"Cookware"},
         {key:"947990010", text:"Other"}
      ]
   },
   { 
      name:"Product Category", 
      defaultSelection:"Select an option",
      key:"product_category", 
      type:fieldTypes.dropdownSingle,
      required:true,
      options:[
         {key:"947990000", text:"Albertsons Private Label"},
         {key:"947990001", text:"National Brand"},
         {key:"947990002", text:"Not for Resale"},
         {key:"947990003", text:"Albertsons Self-Manufacturing"},
         {key:"947990004", text:"Other"}
      ]
   },
   { 
      name:"Testing Performed", 
      defaultSelection:"Select an option",
      key:"testing_performed", 
      type:fieldTypes.dropdownSingle,
      required:true,
      options:[
         {key:"947990000", text:"Yes"},
         {key:"947990001", text:"No"},
         {key:"947990002", text:"Unknown"}
      ]
   },
   { 
      name:"Chemical Substance", 
      defaultValue:"N/A",
      key:"chemical_substance", 
      type:fieldTypes.textSingleline,
      placeHolder:"Chemical Substance",
   },
   { 
      name:"PPM Concentration",
      defaultValue:"N/A",
      key:"ppm", 
      type:fieldTypes.textSingleline,
      placeHolder:"PPM Concentration"
   },
]








