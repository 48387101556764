import React,{useState,useRef, useEffect} from 'react'
import Footer from './components/footer';
import {IFetchError, IResponseValidationData, ISurveyData, ISubmitStatus, ISubmitData} from './types'
import Questions from './components/questions';
import SubmitButton from './components/submitButton';
import SurveySubmitHandlingComponenet from './components/surveySubmitHandlingComponenet';
import LoadingScreen from './components/loadingScreen';
import ErrorScreen from './components/errorScreen';
import SurveyDataValidationScreen from './components/surveyDataValidationFailScreen';
import Header from '../header'
import { divideSurveyIntoChunks, validateSurveySubmitData, sendWholeSurveyResponse, getHeaders} from './utils'
import axios, { AxiosError } from 'axios';
import './index.css';



const ChemicalSurvey = () => {
    const [surveyData, setSurveyData] = useState<ISurveyData | null>(null)
    const [loading, setLoading] = useState<boolean>(true)
    const [submitInitiated, setSubmitInitiated] = useState<boolean>(false)
    const [error, setError] = useState<IFetchError>({isError:false, errorData:null})
    const [surveyDataValidation, setSurveyDataValidation] = useState<IResponseValidationData>({questions:[], hasInvalidQuestions:false})
    const [submitStatus, setSubmitStatus] = useState<ISubmitStatus>({isBeingSubmitted:false, surveyChunks:[]})
    // const [submitStatus, setSubmitStatus] = useState<string>("pending")
    const h2Ref = useRef(null)
    
    useEffect(()=>{
        const getParams= () => {
            const params : any = new Proxy(new URLSearchParams(window.location.search), {
                //  @ts-ignore
                get: (searchParams, prop) => searchParams.get(prop),
            })
            return ({
                surveyInviteId:params.surveyInviteId,
                // contactID:params.contactID,
                // SupplierId:params.SupplierId
            })
        }
        const params = getParams()
        if(!surveyData){
            axios.get(`${process.env.REACT_APP_API_URL}/GetSurveyDetails`, {
                headers:getHeaders(),
                params: params
            }).then(res => {
                setLoading(false)
                setSurveyData({...res.data, SurveyInviteId: params.surveyInviteId})
            }).catch(errorData => {
                setLoading(false)
                setError({isError:true, errorData})
            })
        }
    },[surveyData])

    useEffect(()=>{
        if(surveyData){
            // @ts-ignore
            h2Ref.current.innerHTML = surveyData.SurveyDescription
        }
    },[surveyData])

    const resetSubmitError = () => {
        setError({isError:false, errorData:null, showErrorButton:false, errorQuestionChunk:null})
    }

    const setChunkSubmitError = (errorData: any, chunk?: ISubmitData) => {
        setSubmitInitiated(false)
        setSubmitStatus({isBeingSubmitted:false, surveyChunks:[]})
        setError({isError:true, errorData, showErrorButton:true, errorQuestionChunk:chunk})
    }
    const onSubmitClick = async () => {
        // setSubmitStatus({isBeingSubmitted:true, surveyChunks:[]})
        setSubmitInitiated(true)
        const validationData = surveyData && validateSurveySubmitData(surveyData)
        if(validationData && !validationData.hasInvalidQuestions){
            surveyData && sendWholeSurveyResponse(surveyData).then(newSurveyResponseId =>{
                const surveyChunks = divideSurveyIntoChunks(surveyData)
                setSubmitInitiated(false)
                setSubmitStatus({isBeingSubmitted:true, surveyChunks:surveyChunks, newSurveyResponseId})
            }).catch(errorData => {
                setSubmitInitiated(false)
                setError({isError:true, errorData, showErrorButton:true, errorQuestionChunk:null})
            })
        }else if(validationData && validationData.hasInvalidQuestions){
            setSubmitInitiated(false)
            setSurveyDataValidation(validationData)
        }else{
            setSubmitInitiated(false)
        }
    }

    return (
        <>
            <Header userFullName={surveyData?.CustomerName}/>
            <div style={{position:"relative"}}>
                {/* <button onClick={()=>submitFile(surveyData)}>Submit</button> */}
                <h1 style={{paddingTop:"7rem"}}>{surveyData?.SurveyTitle}</h1>
                <p ref={h2Ref} style={{paddingBottom:"2rem"}}>{surveyData?.SurveyDescription}</p>
                {surveyData &&  <Questions surveyData={surveyData} setSurveyData={setSurveyData}/>}
                <Footer footerText={surveyData?.FooterText}/>
                <SubmitButton 
                    isBeingSubmitted={submitStatus.isBeingSubmitted}
                    onSubmitClick={onSubmitClick}
                    surveyData={surveyData}
                />
                {submitStatus.isBeingSubmitted && <SurveySubmitHandlingComponenet submitStatus={submitStatus} setChunkSubmitError={setChunkSubmitError}/>}
                {submitInitiated && <LoadingScreen text='Submitting Survey...'/>}
                {surveyDataValidation.hasInvalidQuestions && 
                    <SurveyDataValidationScreen 
                        surveyDataValidation={surveyDataValidation}
                        setSurveyDataValidation={setSurveyDataValidation}
                    />}
            </div>
            {loading && <LoadingScreen text='Loading Survey...'/>}
            {error.isError && <ErrorScreen error={error} submitSurveyAgain={resetSubmitError}/>}
        </>
    )
}

export default ChemicalSurvey