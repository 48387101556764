import React, { useState } from "react";
import "antd/dist/antd.css";
import { InboxOutlined } from "@ant-design/icons";
import { Button, Upload } from "antd";

export interface IProps {
    addFilesToQuestion:(Files: any[]) => void
    validateFile:(files: File[]) => boolean
    // validateFileSize:(files: File[]) => boolean
    // validateFileType:(files: File[]) => boolean
}

const AntFileUpload = (props:IProps):JSX.Element => {
  const [fileList, setFileList] = useState([]);

  const handleChange = (info:any) => {
    let newFileList:any = [...info.fileList]; 
    // const isFileSizeValid = props.validateFileSize(newFileList)
    // const isFileTypeValid = props.validateFileType(newFileList)
    const isFileValid =props.validateFile(newFileList)
    if(isFileValid){
      props.addFilesToQuestion(newFileList)
      setFileList(newFileList);
    }
  };
  const { Dragger } = Upload
  const uploadProps = { 
    onChange: handleChange,
    multiple: true
  };

  
  return (
    <div style={{paddingLeft:"1rem", maxWidth:"25rem"}}>
        <Dragger 
            // listType="picture-card"
            beforeUpload={(file,fileList)=> {
              return false; 
              // props.validateFileSize(fileList)
            }} 
            {...uploadProps} 
            fileList={fileList}
        >
            <p className="ant-upload-drag-icon">
              <InboxOutlined />
            </p>
            <p className="ant-upload-text">Click or drag file to this area to upload</p>
        </Dragger>
    </div>
  );
};

export default AntFileUpload;
