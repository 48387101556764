import React from 'react'
import {styles as globalStyle} from '../styles'
import { IFetchError } from '../../types';
import { Button } from "antd";
interface IProps {
    error:IFetchError
    submitSurveyAgain:() => void
}
const ErrorScreen = (props:IProps) => {


  return (
    // @ts-ignore
    <div style={{...globalStyle.translusentScreenStyle.outterBox, background: "rgba(255,255,255, 0.95 )"}}>
      { // @ts-ignore
        <div  style={globalStyle.translusentScreenStyle.innerBox}>
          <div  style={styles.lastWrapper}> 
            <img src={process.env.PUBLIC_URL+"error.png"} alt="error" style={{width:"26px"}}/>
            <p  style={styles.text}>{props?.error?.errorData?.message}</p>
            {props.error.errorQuestionChunk && <p>Error in <b> {props.error.errorQuestionChunk.QuestionResponse.QuestionGroupName}</b> {props.error.errorQuestionChunk.QuestionResponse.QuestionName}</p>}
            {props.error.showErrorButton && <Button type="primary" danger ghost
                onClick={props.submitSurveyAgain}
                >
                Resubmit Survey
            </Button>}
          </div>
        </div>}
    </div>
  )
}

export default ErrorScreen


const styles = {
    wrapper: {
      background: "rgba(255,255,255,1)",
      width: "100%",
      top: "0",
      height: "120%",
      position: "absolute"
    },
    subWrapper: {
      marginTop: "25rem",
      width: "100%",
      position:"fixed"
    },
    lastWrapper: { maxWidth: "37rem", margin: "auto" },
    text:{paddingTop:"1rem", fontSize:"1.25rem"}
  }