import React,{Dispatch,SetStateAction} from 'react';
import { IContextualMenuProps, IIconProps } from '@fluentui/react';
import { CommandButton } from '@fluentui/react/lib/Button';
import {styles} from "../styles"
import { IPanelData, ISelectedItem } from '../../types';
import { IQuestion } from '../../../product-survey/types';

export interface IProps {
    addProject:any
    selectedItem:ISelectedItem
    setSelectedItem:Dispatch<SetStateAction<ISelectedItem>>
    addProductToPanelDataAndRemoveSelection:() => void
    deleteProductAndRemoveSelection:()=>void
}
const {addProjectButton} = styles

const AddNewProjectButton= (props:IProps):JSX.Element => {
    
    const {
        addProject,
        selectedItem,
        setSelectedItem,
        addProductToPanelDataAndRemoveSelection,
        deleteProductAndRemoveSelection
    } = props
    
    
    const addIcon: IIconProps = { iconName: 'Add' };
    const delteIcon: IIconProps = { iconName: 'Delete' };
    const editIcon: IIconProps = { iconName: 'Edit' };
    const cancelIcon: IIconProps = { iconName: 'Cancel' };


    return (
        <div style={addProjectButton.wrapper}>
            {/* {selectedItem.isSelected ? */}
                <CommandButton
                    text="Add Product"
                    iconProps={addIcon}
                    onClick={addProject}
                    disabled={selectedItem.isSelected}
                    // Optional callback to customize menu rendering
                    // Optional callback to do other actions (besides opening the menu) on click
                    // By default, the ContextualMenu is re-created each time it's shown and destroyed when closed.
                    // Uncomment the next line to hide the ContextualMenu but persist it in the DOM instead.
                    // persistMenu={true}
                    allowDisabledFocus
                />
            {/* <> */}
                <CommandButton
                    text="Edit Product"
                    iconProps={editIcon}
                    onClick={addProductToPanelDataAndRemoveSelection}
                    disabled={!selectedItem.isSelected}
                    // Optional callback to customize menu rendering
                    // Optional callback to do other actions (besides opening the menu) on click
                    // By default, the ContextualMenu is re-created each time it's shown and destroyed when closed.
                    // Uncomment the next line to hide the ContextualMenu but persist it in the DOM instead.
                    // persistMenu={true}
                    allowDisabledFocus
                />
                <CommandButton
                    text="Delete Product"
                    iconProps={delteIcon}
                    onClick={deleteProductAndRemoveSelection}
                    disabled={!selectedItem.isSelected}
                    // Optional callback to customize menu rendering
                    // Optional callback to do other actions (besides opening the menu) on click
                    // By default, the ContextualMenu is re-created each time it's shown and destroyed when closed.
                    // Uncomment the next line to hide the ContextualMenu but persist it in the DOM instead.
                    // persistMenu={true}
                    allowDisabledFocus
                />
                {selectedItem.isSelected && <CommandButton
                    text="Cancel"
                    iconProps={cancelIcon}
                    onClick={()=>setSelectedItem({isSelected:false})}
                    // Optional callback to customize menu rendering
                    // Optional callback to do other actions (besides opening the menu) on click
                    // By default, the ContextualMenu is re-created each time it's shown and destroyed when closed.
                    // Uncomment the next line to hide the ContextualMenu but persist it in the DOM instead.
                    // persistMenu={true}
                    allowDisabledFocus
                />}
            {/* </> */}
            {/* : */}
            
            {/* } */}
        </div>
    );
};

export default AddNewProjectButton
