import React, {Dispatch, SetStateAction} from 'react';
import {styles as globalStyle} from '../../../styles'
import { IFileNameValidation, IFileSizeValidationMetadata, IFileTypeValidation } from '..';
import {getSize} from '..'
import { Button } from 'antd';
interface IProps {
    fileSizeErrorData:IFileSizeValidationMetadata
    setFileSizeErrorData:Dispatch<SetStateAction<IFileSizeValidationMetadata>>
    fileTypeErrorData: IFileTypeValidation
    setFileTypeErrorData: Dispatch<SetStateAction<IFileTypeValidation>>
    fileNameErrorData:IFileNameValidation
    setFileNameErrorData:Dispatch<SetStateAction<IFileNameValidation>>
}

const FileUploadValidationScreen = (props:IProps):JSX.Element => {
  
    const {
        fileSizeErrorData:{
            isExceeding:invalidFileSize,
            totalExceeding,
            totalUploaded
        },
        setFileSizeErrorData: setFileSizeData,
        fileTypeErrorData:{
            isNotValid:invalidFileType,
            invalidType
        },
        setFileTypeErrorData: setFileTypeData,
        fileNameErrorData:{
          isNotValid:invalidFileName,
          invalidName
        },
        setFileNameErrorData
    } = props

    const resetFileUpload = () => {
        if(invalidFileSize){
            setFileSizeData({
                isExceeding:false,
                totalBeingUploaded:0,
                totalExceeding:0,
                totalUploaded
            })
        }else if(invalidFileType){
            setFileTypeData({invalidType:"",isNotValid:false})
        }else if(invalidFileName){
            setFileNameErrorData({invalidName:"", isNotValid:false})
        }
    }
    const invalidFileSizeErrorMessage = `Total upload size is being exceed by ${getSize(totalExceeding, "kb")} or ${getSize(totalExceeding, "mb")}`
    const invalidFileTypeErrorMessage = `${invalidType} file type is restricted`
    const invalidFileNameErrorMessage = `file with name` + <b>{invalidName}</b> + `already exists`
  return (
    // @ts-ignore
    <div style={globalStyle.translusentScreenStyle.outterBox}>
      { // @ts-ignore
        <div  style={globalStyle.translusentScreenStyle.innerBox}>
          <div  style={styles.lastWrapper}> 
          {
            // @ts-ignore
            <div style={styles.box}>
              {
                <>
                  {// @ts-ignore
                      invalidFileSize && <p style={styles.taskHeading}>{`Exceeding Upload Limit (8 MBs)`}</p>
                  }
                  {// @ts-ignore
                      invalidFileType && <p style={styles.taskHeading}>{`Invalid File Type`}</p>
                  }
                  {// @ts-ignore
                      invalidFileName && <p style={styles.taskHeading}>{`Duplicate file names are restricted`}</p>
                  }
                  <>
                {   // @ts-ignore
                  <div style={styles.questionNameBox}>
                      {/* <ExceedDataInfo 
                          textLabel="Existing File Size: " 
                          textValue={`${getSize(totalUploaded, "kb")} or ${getSize(totalUploaded, "mb")}`} 
                      />
                      <ExceedDataInfo 
                          textLabel="Current Upload Size: " 
                          textValue={`${getSize(totalBeingUploaded, "kb")} or ${getSize(totalBeingUploaded, "mb")}`} 
                      /> */}
                      {invalidFileSize && <p style={{...styles.messageName, textAlign: "center"}}>Total upload size is being exceed by <b>{getSize(totalExceeding, "kb")}</b> or <b>{getSize(totalExceeding, "mb")}</b></p>}
                      {invalidFileType && <p style={{...styles.messageName, textAlign: "center"}}><b>{invalidType}</b> file type is restricted</p>}
                      {invalidFileName && <p style={{...styles.messageName, textAlign: "center"}}>file with name <b>{invalidName}</b> already exists</p>}
                      <div style={styles.buttonDiv}>
                          <Button type="primary" danger ghost
                          onClick={resetFileUpload}
                          >
                              Cancel Upload
                          </Button>
                      </div>
                    </div>}
                  </>
                </> 
              }
            </div>
          }
          </div>
      </div>}
    </div>
  );
};

export default FileUploadValidationScreen

const styles = {
  wrapper: {
    background: "rgba(255,255,255,0.9)",
    width: "100%",
    top: "0",
    left: "0",
    height: "120%",
    position: "absolute",
    zIndex:"200"
  },
  subWrapper: {
    marginTop: "21rem",
    width: "100%",
    position:"fixed"
  },
  lastWrapper: { maxWidth: "37rem", margin: "auto", marginTop:"-5rem 0rem 0rem 0rem" },
  box:{
    background: "white",
    boxShadow: "0 4px 8px 0 rgb(0 0 0 / 5%), 0 6px 20px 0 rgb(0 0 0 / 5%)",
    padding: "0 1rem 1rem 1rem",
    textAlign: "left"
  },
  heading:{
    display: "flex",
    // background: "rgb(0, 160, 224)",
    margin: "0 -1rem",
    height: "3rem",
    paddingLeft: "24px",
    alignItems: "center",
    color: "white",
    fontSize: "16px",
    fontWeight: "bold"
  },
  taskHeading: {
    display: "block",
    textAlign: "center",
    margin: "0px -1rem",
    padding: "18px 0px",
    fontSize: "17px",
    fontWeight: 600
  },
  messageName: {
    margin:"auto",
  },
  questionNameBox: {
    // marginTop: "1rem",
    display: "flex",
    // paddingLeft: "1rem",
    // paddingTop: "1rem",
    justifyContent: "flex-start",
    flexDirection: "column"
  },
  buttonDiv:{
    display: "flex",
    justifyContent: "center",
    marginTop: "2rem",
    padding:"0"
  }
}
