import * as React from 'react';
import { Checkbox, Stack } from '@fluentui/react';

// Used to add spacing between example checkboxes
const stackTokens = { childrenGap: 10 };

export const ChoiceMultipleInput: React.FunctionComponent = () => {
  // These checkboxes are uncontrolled because they don't set the `checked` prop.
  return (
    <Stack tokens={stackTokens}>
      <Checkbox label="Unchecked checkbox (uncontrolled)" onChange={_onChange} />
      <Checkbox label="Unchecked checkbox (uncontrolled)" onChange={_onChange} />
      <Checkbox label="Unchecked checkbox (uncontrolled)" onChange={_onChange} />
      <Checkbox label="Unchecked checkbox (uncontrolled)" onChange={_onChange} />
    </Stack>
  );
};

function _onChange(ev?: React.FormEvent<HTMLElement | HTMLInputElement>, isChecked?: boolean) {
  console.log(`The option has been changed to ${isChecked}.`);
}
