import {
  IQuestion,
  IProduct,
  ISubmitData,
  ISurveyData, 
  IQuestionGroup, 
  IFileMetaData, 
  ISurveyDataWithOutFiles, 
  IQuestionGroupWithOutFiles, 
  IQuestionpWithOutFiles
} from '../types'
import {questionType} from '../constants'
import axios from 'axios'
import { getHeaders } from '.';

declare global {
  interface window {
    globalSurveyResponseId: string;
  }
}
export interface IQuestionResponseSpecifics {
  QuestionId: string
  Type:string
  Response: string
  Products?: IProduct[]
  Files?: string | File[] 
}

const getTextAndChoiceResponse = (question:IQuestion):IQuestionResponseSpecifics | undefined=> {
    if(question.Response){
      const response:IQuestionResponseSpecifics = {
        QuestionId:question.Id,
        Response: question.Response,
        Type:question.Type,
        Files:"",
        Products:[],
      }
      return response
    }
  }

  const getFilesResponse = (question:IQuestion):IQuestionResponseSpecifics | undefined=> {
    if(question.Files){
      const response:IQuestionResponseSpecifics = {
        QuestionId:question.Id,
        Response: "Please find attached.",
        Products:[],
        Type:question.Type,
        Files:question.Files,
      }
      return response
    }
  }

  // const  getProductArray = (productResponses:IProduct[]):IProductResponse[] => {
  //     const flattenProducts: IProductResponse[] = []
  //     productResponses.forEach(productResponse => {
  //       const flatProduct :IProductResponse = {
  //         "Product Category":productResponse.product_category.text,
  //         "Testing performed":productResponse.testing_performed.text,
  //         ChemicalSubstance:productResponse.chemical_substance || "N/A",
  //         Location:productResponse.location.text,
  //         PPMConcentration:productResponse.ppm || "N/A",
  //         ProductName:productResponse.productName,
  //         ProductType:productResponse.product_type.text,
  //         Purpose:productResponse.purpose,
  //         States:productResponse.states_where_manufactured,
  //         UPC:productResponse.upc
  //       }
  //       flattenProducts.push(flatProduct)
  //     })
  //     return flattenProducts
  // }

  const getProductResponse = (question:IQuestion):IQuestionResponseSpecifics | undefined=> {
    
    if(question.ProductResponses && question.ProductResponses.length > 0){
      const response:IQuestionResponseSpecifics = {
        QuestionId:question.Id,
        Response: "Please review details on products sub-grid",
        Products:question.ProductResponses,
        Type:question.Type,
        Files:question.Files,
      }
      return response
    }

  }

  const getQuestionResponse = (question:IQuestion):IQuestionResponseSpecifics | undefined | null => {
    switch(question.Type){
      case (questionType.TextMultiline) : return getTextAndChoiceResponse(question)
      case (questionType.ChoiceSingleSelect) : return getTextAndChoiceResponse(question)
      case (questionType.Attachments) : return getFilesResponse(question)
      case (questionType.ProductGrid) : return getProductResponse(question)
      default: return null
    }
  }

  const getParams= () => {
    const params : any = new Proxy(new URLSearchParams(window.location.search), {
        //  @ts-ignore
        get: (searchParams, prop) => searchParams.get(prop),
    })
    return ({
        surveyInviteId:params.surveyInviteId,
        // contactID:params.contactID,
        // SupplierId:params.SupplierId
    })
}


  export const divideSurveyIntoChunks = (surveyData:ISurveyData):ISubmitData[] | []=> {
      const SurveyInviteId = getParams().surveyInviteId
      const surveySubmitDataChunks:ISubmitData[] = []
      surveyData.QuestionGroups.forEach(group =>{
        group.Questions.forEach(question => {
          const QuestionResponse = getQuestionResponse(question)
          if(QuestionResponse){
            const newSubmitChunk:ISubmitData = {
              CustomerId:surveyData.CustomerId,
              SupplierId:surveyData.SupplierId,
              SurveyId:surveyData.SurveyId,
              SourceSurveyIdentifier:surveyData.SourceSurveyIdentifier,
              SurveyInviteId,
              QuestionResponse:{
                ...QuestionResponse,
                QuestionName:question.Title,
                QuestionGroupName:group.Title
              }
            }
            surveySubmitDataChunks.push(newSubmitChunk)
          }
        })
      })
      return surveySubmitDataChunks
  }

  export const submitFilesQuestion =  (surveyChunk:ISubmitData, newSurveyResponseId:string): Promise<any>| null => {
    if(surveyChunk.QuestionResponse.Files){
      const formData: FormData = new FormData();
      const uploadUrl = `${process.env.REACT_APP_API_URL}/submitFileResponse`
      const dataWithFileRemoved:ISubmitData = {
        ...surveyChunk, 
        SurveyResponseId:newSurveyResponseId,
        QuestionResponse:{
          ...surveyChunk.QuestionResponse, 
          Files: ""
        }
      }
      const attachmentMetaData = JSON.stringify({...dataWithFileRemoved})
      formData.append('metaData', attachmentMetaData)
      for (let file of surveyChunk.QuestionResponse.Files) {
        formData.append("file", file);
      }
      return axios
        .post(uploadUrl, formData, {
          headers: getHeaders("multipart/form-data")
        })
    }
    return null
  }

  export const submitNormalQuestion = (surveyChunk:ISubmitData, newSurveyResponseId:string): Promise<any> => {
      const uploadUrl = `${process.env.REACT_APP_API_URL}/SurveyResponse`
      const dataWithFileRemoved:ISubmitData = {
          ...surveyChunk, 
          SurveyResponseId:newSurveyResponseId,
          QuestionResponse:{
            ...surveyChunk.QuestionResponse, 
            Files: ""
          }
        }
      return axios
        .post(uploadUrl, JSON.stringify({...dataWithFileRemoved}), {
          headers: getHeaders()
        })
  }

  const removeFilesFromSurvey = (surveyData:ISurveyData):ISurveyDataWithOutFiles => {

    const getFileMetaData = (files:File[]):IFileMetaData[] | []=> {
      const metaData = []
      for (let file of files) {
          metaData.push({
            name:file.name,
            size: file.size,
            type:file.type,
            lastModified:file.lastModified,
          })
      }
      return metaData
    }

    const questionGroupWithFilesRemoved:IQuestionGroupWithOutFiles[] = surveyData.QuestionGroups.map((group:IQuestionGroup) => {
      const newQuestions:IQuestionpWithOutFiles[] = group.Questions.map(question => {
        if(question.Type === questionType.Attachments && question.Files){
          const fileMetaData: string | IFileMetaData[] = typeof question.Files === "string" ? question.Files : getFileMetaData(question.Files)
          return ({...question, Files:fileMetaData})
        }
        return ({...question, Files:""})
      })
      return ({...group, Questions:newQuestions})
    })

    return ({...surveyData, QuestionGroups:questionGroupWithFilesRemoved})
  }

  const sendFilelessSurvey = async (surveyData:ISurveyData):Promise<any> => {
    const filelessSurveyData = removeFilesFromSurvey(surveyData)
    const uploadUrl = `${process.env.REACT_APP_API_URL}/SurveyResponse`
    const newSurveyResponseId = await axios.post(uploadUrl, JSON.stringify(filelessSurveyData), {
      headers: getHeaders()
    })
    return newSurveyResponseId
  }

  export const sendWholeSurveyResponse = async (surveyData:ISurveyData):Promise<string> => {
    // const surveyResponseId = sessionStorage.getItem("surveyResponseId")
    // if(!surveyResponseId){
      const res = await sendFilelessSurvey(surveyData)
      return res.data.newlyCreatedSurveyResponseId
    // }
    // return surveyResponseId
  }

  export const submitSurveyChunk = async (surveyChunks:ISubmitData[], newSurveyResponseId:string, handelSubmitScreenStatus:(surveyChunk:ISubmitData) => void) => {
    surveyChunks.forEach(async surveyChunk => {
      // handelSubmitScreenStatus(surveyChunk)
      if(surveyChunk.QuestionResponse.Type === questionType.Attachments){
        return submitFilesQuestion(surveyChunk, newSurveyResponseId)
      }
      return submitNormalQuestion(surveyChunk, newSurveyResponseId)
      // switch(surveyChunk.QuestionResponse.Type){
      //   case questionType.Attachments : return  submitFilesQuestion(surveyChunk, newSurveyResponseId) 
      //   default : return submitNormalQuestion(surveyChunk, newSurveyResponseId)
      // }
    });
  }










