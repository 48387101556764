import React from 'react'
import {styles} from '../styles'
interface IProps {
  footerText:string | undefined
}
const Footer = (props:IProps) => {
  return (
    // @ts-ignore
    <div className='footer' style={styles.footer}> <div  style={styles.footerBottomText}>{props.footerText}</div> </div>
  )
}

export default Footer