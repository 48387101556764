import React,{useState, Dispatch, SetStateAction} from 'react'
import { IOptions, IProduct, IProductResponse, IQuestion, IQuestionGroup, IResponseOption, ISubmitData, ISurveyData } from '../../types'
import ProductGridQuestion from './productGridQuestion'
import {questionType} from '../../constants'
import ChoiceSingleSelectQuestion from './choiceSingleSelectQuestion'
import TextMultilineQuestion from './textMultilineQuestion'
import AttachmentQuestion from './attachmentQuestion'
import {styles} from '../styles'
interface IProps {
  surveyData:ISurveyData,
  setSurveyData:Dispatch<SetStateAction<ISurveyData | null>>
}

const Questions = (props:IProps):JSX.Element => {
  const {surveyData, setSurveyData} = props




  const setNewQuestion = (title:string, newQuestion:IQuestion) => {
    
    const updateNewQuestion = (question:IQuestion) => {
      
      if(question.Id === newQuestion.Id){
        return newQuestion
        }
        return question
    }

    const newQuestionsGroups = surveyData.QuestionGroups.map(questionGroup =>{
      
      if(questionGroup.Title === title){
        const newQuestions= questionGroup.Questions.map(updateNewQuestion)
        return {...questionGroup, Questions:newQuestions}
      }
      return questionGroup
    })
    
    

    setSurveyData({...surveyData, QuestionGroups:newQuestionsGroups})
  }

  const getQuestionComponent =(question:IQuestion, Title:string):JSX.Element =>{
    switch(question.Type){
      case questionType.ProductGrid : return <ProductGridQuestion 
        key={question.Id}
        title={Title}
        setNewQuestion={setNewQuestion}
        question={question}
      />
      case questionType.ChoiceSingleSelect : return <ChoiceSingleSelectQuestion 
        key={question.Id}
        title={Title}
        setNewQuestion={setNewQuestion}
        question={question}
      />
      case questionType.TextMultiline : return <TextMultilineQuestion 
        key={question.Id}
        title={Title}
        setNewQuestion={setNewQuestion}
        question={question}
      />
      case questionType.Attachments : return <AttachmentQuestion 
        key={question.Id}
        title={Title}
        setNewQuestion={setNewQuestion}
        question={question}
      />
      default: return <div>Question Type not supported</div>
    }
  }
  
  return (
    <div>
      {surveyData.QuestionGroups.map(questionGroup =>{
          return (
            <div className="questionGroup" style={styles.questionGroup.questionGroupWrapper} key={questionGroup.Title}>
                <h2 style={styles.questionGroup.questionGroupTitle}>{questionGroup.Title}</h2>
                {questionGroup.Questions.map((question,index,array) => {
                    const isTheLastItem = index + 1 === array.length
                    return(
                      <div key={question.Id}>
                        <div style={styles.questionGroup.questionDiv} >
                          <div style={{display:'flex'}}>
                            <p 
                              // @ts-ignore
                              style={styles.questionGroup.questionTitle}>
                              {question.Title}
                            </p>
                           {question.Required && <p style={styles.questionGroup.required}>( Required )</p>}
                          </div>
                          {getQuestionComponent(question,questionGroup.Title)}
                        </div>
                        {!isTheLastItem && <div 
                          style={{
                            height: "2px",
                            margin: "1rem 0rem 1rem 0rem",
                            background: "whitesmoke"
                          }}>
                        </div>}
                      </div>
                    )
                })}
            </div>)
        })}
    </div>
  )
}

export default Questions