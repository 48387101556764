import React, {Dispatch, useState} from 'react'
import { DetailsList, SelectionMode } from '@fluentui/react/lib/DetailsList';
import {getGridColumns} from "../../../../utils"
import {IProduct, IPanelData, IQuestion, ISelectedItem} from "../../../../types"
import {styles} from '../../../styles'
import AddProjectButton from '../../../addProjectButton'
export interface IProps {
    panelData:IPanelData
    setPanelData:Dispatch<IPanelData>
    question:IQuestion
    addFilesToQuestion:(files: File[]) => void
    deleteProductFromQuestion:(productIndex: number) => void
}

// const tempItems:IProduct[] = [
//     {productName:"Project 2", upc:"3456",chemPresent:"UO243",concentration:"56(ppm)"},
//     {productName:"Project 1", upc:"1234",chemPresent:"YH243",concentration:"4001(ppm)"}
// ]

const QuestionGrid = (props:IProps):JSX.Element => {

    const {
        panelData, 
        setPanelData, 
        question,
        addFilesToQuestion,
        deleteProductFromQuestion
    } = props
    const columns = getGridColumns()
    
    
    const [selectedItem, setSelectedItem] = useState<ISelectedItem>({isSelected:false})
    // const selection = new Selection({
        //     onSelectionChanged: () => {
            //         
            //         setSelectedItem(selection.getSelection()[0])
            //     }
            // })
            
            
    
    const productResponseWithOnlyStringValues = question.ProductResponses && question.ProductResponses?.map(product=>{
        
        const onlyStringValueProduct = Object.keys(product).reduce(
            (previousValue, currentValue) => {
              
              // @ts-ignore
              const newValue = typeof product[currentValue] === "object" ? product[currentValue].text : product[currentValue]
              return {...previousValue, [currentValue]:newValue}
            },
            {}
          );

        return onlyStringValueProduct
          
    })
    
    const openPanelWithQuestionData = () => {
        setPanelData({isOpen:true, question})
    }

    const getActualProduct = (productId:string):IProduct | undefined=> {
        let fetchedProduct
        question.ProductResponses && question.ProductResponses.forEach(product =>{
            if(product.id === productId){
                fetchedProduct = product
            }
        })
        return fetchedProduct
    }
    const onActiveItemChanged = (product:IProduct,index:any) => {

        setSelectedItem({isSelected:true,product:getActualProduct(product.id),index})

    }

    const addProductToPanelDataAndRemoveSelection = () => {
        if(selectedItem.isSelected && typeof selectedItem.index !== "undefined"){
            setSelectedItem({isSelected:false})
            setPanelData({isOpen:true,question,product:selectedItem.product})
        }
    }

    const deleteProductAndRemoveSelection = () => {
        if(selectedItem.isSelected && typeof selectedItem.index !== "undefined"){
            setSelectedItem({isSelected:false})
            deleteProductFromQuestion(selectedItem.index)
        }
    }
    return (
        
    <div style={styles.questionGrid.wrapper}>
        {/* <Text key={'title'} variant={"xLarge" as ITextProps['variant']} block style={{textAlign:'left', paddingLeft:'1rem', marginBottom:'1rem'}}>
            {question.Title}
        </Text> */}
        {/* <div 
            style={styles.questionGrid.description}
        >
            <Text key={'title'} variant={"mediumPlus" as ITextProps['variant']} block>
                {question.SubTitle}
            </Text>
        </div> */}
        <AddProjectButton 
            addProject={openPanelWithQuestionData}
            selectedItem={selectedItem}
            setSelectedItem={setSelectedItem}
            addProductToPanelDataAndRemoveSelection={addProductToPanelDataAndRemoveSelection}
            deleteProductAndRemoveSelection={deleteProductAndRemoveSelection}
        />
        {
            // productResponseWithOnlyStringValues && 
            // productResponseWithOnlyStringValues.length > 0 && 
            <DetailsList
                items={productResponseWithOnlyStringValues || []}
                columns={columns}
                className={"detailsList"}
                onActiveItemChanged={onActiveItemChanged}
                // selection={selection}
                selectionMode={SelectionMode.none}
                selectionPreservedOnEmptyClick={true}
                onRenderRow={ (props, defaultRender) => {
                    if(selectedItem.isSelected && props?.itemIndex === selectedItem.index){
                        return <div className='red'>
                        {props && defaultRender && defaultRender({...props, styles: {root: {background: "#e1e1d0"}}})}            
                        </div>
                    }
                    return <div>{props && defaultRender && defaultRender({...props})}</div>
                } }

            />
        }
    </div>
    )
}

export default QuestionGrid