import React, {Dispatch, SetStateAction} from 'react' 
import { TextField, MaskedTextField } from '@fluentui/react/lib/TextField';
import { Stack, IStackProps, IStackStyles } from '@fluentui/react/lib/Stack';
// import {IInputContainerData} from "../types"
import {fieldTypes} from "../../constants"
import {IGridColumn, IProduct} from '../../types'

interface IProps {
  field:IGridColumn
  type?:string
  product?:IProduct
  newProduct?:IProduct
  setNewProduct?:Dispatch<SetStateAction<IProduct>>
}
const stackStyles: Partial<IStackStyles> = { root: { width: 645} };

export const TextInput = (props:IProps): JSX.Element => {

  const {
    type,
    field,
    product,
    setNewProduct,
    newProduct
  } = props
  
  const onChange = (event: any, newValue?:string) => {
    newProduct && setNewProduct && setNewProduct({...newProduct, [field.key]:newValue})
  }
        // @ts-ignore

  
  
  return (
    <Stack verticalFill>
      <TextField  
        width={20}
        multiline={type && type === fieldTypes.textMultiline ? true : false} 
        label={field.name}
        onChange={onChange}
        // @ts-ignore
        value={newProduct[field.key]}
        required={field.required}
      />
    </Stack>

  )
}