import {  IColumn } from '@fluentui/react/lib/DetailsList';
import {gridColumns} from "../constants"
import { IQuestion, ISurveyData, IResponseValidationData } from '../types';
import { questionType } from '../constants';
export {
  divideSurveyIntoChunks, 
  submitSurveyChunk, 
  sendWholeSurveyResponse,
  submitFilesQuestion,
  submitNormalQuestion
} from './submitSurveyByChunks'
export const getGridColumns = ():IColumn[] => {
    const columns: IColumn[] = gridColumns.map((column, i) => ({
            key: `column${i}`,
            name: column.gridName || column.name,
            fieldName: column.key,
            minWidth: 30,
            maxWidth: 125,
            isRowHeader: true,
            isResizable: true,
            // isSorted: true,
            isMultiLine:true,
            isSortedDescending: false,
            sortAscendingAriaLabel: 'Sorted A to Z',
            sortDescendingAriaLabel: 'Sorted Z to A',
          //   onColumnClick: this._onColumnClick,
            data: 'string',
            isPadded: true,
            
        }
    ))
    
    return columns
}

export const getHeaders = (contentType?:string):any => {
  return ({
      "Content-Type": contentType || "application/json",
      "Ocp-Apim-Subscription-Key": process.env.REACT_APP_OCP_APIM_SUBSCRIPTION_KEY
  })
}

const getInvalidQuestions = (surveyData:ISurveyData):IQuestion[] => {
  const invalidQuestionArray:IQuestion[] = []

  const checkTextAndChoiceQuestionValidity = (question:IQuestion, GroupName:string) => {
    if(question.Required){
      !question.Response && invalidQuestionArray.push({...question, GroupName})
    }
  }

  const checkAttachmentQuestionValidity = (question:IQuestion, GroupName:string) => {
    if(question.Required){
      if(!question.Files || question.Files.length < 1){
        invalidQuestionArray.push({...question, GroupName})
      }
    }
  }

  const checkProductQuestionValidity = (question:IQuestion, GroupName:string) => {
    if(question.Required){
      if(!question.ProductResponses|| question.ProductResponses.length < 1){
        invalidQuestionArray.push({...question, GroupName})
      }
    }
  }

  surveyData.QuestionGroups.forEach(group=>{
    group.Questions.forEach(question =>{
      switch(question.Type){
        case questionType.Attachments: return checkAttachmentQuestionValidity(question, group.Title)
        case questionType.ProductGrid: return checkProductQuestionValidity(question, group.Title)
        default: return checkTextAndChoiceQuestionValidity(question, group.Title)
      }
    })
  })

  return invalidQuestionArray
}
export const validateSurveySubmitData = (surveyData:ISurveyData):IResponseValidationData => {
  const inValidQuestionsArray : IQuestion[] = getInvalidQuestions(surveyData)
  if(inValidQuestionsArray.length > 0){
    return ({hasInvalidQuestions:true, questions:inValidQuestionsArray})
  }
  return ({hasInvalidQuestions:false, questions:inValidQuestionsArray})
}