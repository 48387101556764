import React, {Dispatch, useState, SetStateAction} from 'react'
import { Text, ITextProps } from '@fluentui/react/lib/Text';
import {MessageBar,MessageBarType} from '@fluentui/react/lib/MessageBar'
import {IPanelData,IProduct} from '../../types'
import {TextInput, DropdownInput} from '../inputs'
import {fieldTypes, gridColumns} from '../../constants'
import {styles} from "../styles"

export interface IProps {
  panelData:IPanelData
  setPanelData:Dispatch<IPanelData>
  addProductResponseQuestion:(product: IProduct) => void
  newProduct:IProduct
  setNewProduct:Dispatch<SetStateAction<IProduct>>
  editMode:boolean
  validationErrors:string[]
  setValidationErrors:Dispatch<SetStateAction<string[]>>
}
const {sidePanel} = styles

const NewProjectForm = (props:IProps):JSX.Element => {

  const {
    panelData :{ 
      question,
      product:productBeingEdited,
    },
    editMode,
    addProductResponseQuestion,
    setPanelData,
    newProduct,
    setNewProduct,
    validationErrors,
    setValidationErrors
  } = props
  
  const onSaveClick = ()=>{
    addProductResponseQuestion(newProduct)
  }
  
  return (
    <div style={sidePanel.form} className="formDiv">
      {
        validationErrors.length > 0 &&
        validationErrors.map((item, index)=>{
          return(
            <MessageBar
              messageBarType={MessageBarType.error}
              isMultiline={false}
              key={item}
            > {item} can't be empty</MessageBar>
          )
        })
      }
        {gridColumns.map(field => {
          switch(field.type){
            case fieldTypes.dropdownSingle : return <DropdownInput 
              editMode={editMode}
              key={`${field.key}_${question.Id}`}
              field={field} 
              newProduct={newProduct} 
              setNewProduct={setNewProduct}
            />
            case fieldTypes.textSingleline : return <TextInput 
              key={`${field.key}_${question.Id}`}
              field={field} 
              newProduct={newProduct} 
              setNewProduct={setNewProduct}
            />
            case fieldTypes.textMultiline : return <TextInput 
              key={`${field.key}_${question.Id}`}
              field={field} 
              newProduct={newProduct} 
              setNewProduct={setNewProduct}
              type={fieldTypes.textMultiline}
            />
          }
        })}
    </div>
  )
}

export default NewProjectForm