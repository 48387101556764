import React,{useState,Dispatch,SetStateAction} from 'react'
import {IQuestion} from '../../../types'
import { IStackTokens, Stack } from '@fluentui/react/lib/Stack';
import { ChoiceGroup, IChoiceGroupOption } from '@fluentui/react/lib/ChoiceGroup';
import {styles} from '../../styles'
import QuestionGrid from '../productGridQuestion/questionDataGrid';

interface IProps {
    question:IQuestion
    setNewQuestion:(title: string, newQuestion: IQuestion) => void
    title:string
}
const stackTokens: IStackTokens = { childrenGap: 50 };

const ChoiceSingleSelectQuestion = (props:IProps):JSX.Element => {

    const {question, setNewQuestion, title} = props   
    const options: IChoiceGroupOption[] | undefined = question.ResponseOptions?.map(option => ({key:option.ResponseOptionId, text:option.ResponseOptionText.replaceAll('\"', '')}))


    return (
        options ? 
        // <Stack tokens={stackTokens}>
            <ChoiceGroup
                options={[...options]}
                onChange={(event,choice) =>setNewQuestion(title, {...question, Response:choice?.text})}
            /> 
        // </Stack>
        : <h2>Issue with drop down - can't see options</h2>
      )
}

export default ChoiceSingleSelectQuestion