import React from 'react'
import {styles as globalStyle} from '../styles'
import {  Spin } from 'antd';

interface IProps {
    text:string
}
const LoadingScreen = (props:IProps) => {
  const isSubmitting = props.text.toLowerCase().includes("submit")
  return (
    // @ts-ignore
    <div style={{...globalStyle.translusentScreenStyle.outterBox, background: `rgba(255,255,255, ${isSubmitting ? 0.95 : 1})`}}>
      { // @ts-ignore
        <div  style={globalStyle.translusentScreenStyle.innerBox}>
          <div  style={styles.lastWrapper}> 
          <Spin size="large" />
          <p  style={styles.text}>{props.text}</p>
          </div>
      </div>}
    </div>
  )
}

export default LoadingScreen


const styles = {
    wrapper: {
      background: "rgba(255,255,255,1)",
      width: "100%",
      top: "0",
      height: "120%",
      position: "absolute"
    },
    subWrapper: {
      marginTop: "25rem",
      width: "100%",
      position:"fixed"
    },
    lastWrapper: { maxWidth: "37rem", margin: "auto" },
    text:{paddingTop:"1rem", fontSize:"1.25rem"}
  }