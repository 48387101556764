export const styles = {
    questionGrid:{
        wrapper:{
            // margin: "auto",
            // marginTop: "3rem",
            backgroundColor: "white",            
            // padding:"2rem"                      
                      
        },
        description:{
            textAlign: "left",
            padding:"2rem"
        },
        boldFont:{
            fontWeight:"500"
        }
    },
    addProjectButton:{
        wrapper:{
            backgroundColor: "white",
            display: "flex",
            padding: "4px",
            paddingLeft: "1rem",
            // marginBottom:"1rem"
        }
    },
    sidePanel:{
        form:{
            width: "92%",
            marginLeft: "1rem",
            marginTop: "8px"
        }
    },
    addFileSection:{
        wrapper:{
            backgroundColor: "white",
            display: "flex",
            padding: "4px 4px 4px 1rem",
            marginTop: "1rem",
            ["flex-direction"]: "column",
            alignItems: "flex-start",
            position:"relative"
        },
        fileInput:{
            display:"none"
        },
        fileList: {
            fontSize: "13px",
            textAlign: "initial",
            marginLeft: "3rem",
            fontStyle: "italic"
        },
        description:{
            marginBottom:"1rem"
        }
    },
    footer: {
        // height: "20rem",
        background: "white",
        position: "relative",
        bottom: "-6rem"
      },
    footerBottomText: {
        width:"100%",
        height: "5rem",
        background: "#757575",
        color: "white",
        display: "flex",
        alignItems: "center",
        justifyContent: "center"
    },
    questionGroup : {
        questionGroupTitle: {
            display: "flex",
            height: "4rem",
            color: "white",
            margin: "0",
            marginBottom: "1rem",
            paddingLeft: "2rem",
            background: "#00a0e0",
            alignItems: "center"
        },
        questionGroupWrapper: {
          width: "95%",
          backgroundColor: "white",
          margin: "auto",
          marginBottom: "4rem",
          paddingBottom: "4rem"
        },
        questionTitle: {
          textAlign: "left",
          WebkitFontSmoothing: "antialiased",
          fontSize: "17px",
          fontWeight: 600,
          marginBottom: "2rem",
          color: "rgb(50, 49, 48)"
        },
        questionDiv: { 
            padding: "0 2rem", 
            paddingBottom: "2rem"
        },
        required:{ marginLeft: "1rem", color: "red" }
      },
      translusentScreenStyle:{
        outterBox:{
            display: "flex",
            background: "rgba(255, 255, 255, 0.9)",
            width: "100%",
            top: "0px",
            left:"0px",
            height: "100vh",
            position: "fixed",
            justifyContent: "center",
            alignItems: "center",
            zIndex:100
        },
        innerBox: { width: "100%", position: "fixed" }
      }
}