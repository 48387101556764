import React from 'react'
import Logo from './albertsonLogo'
import { UserOutlined } from "@ant-design/icons";

interface IProps {
  userFullName?:string
}

const Header = ({userFullName}:IProps) => {
  return (
    // @ts-ignore
    <div style={styles.headerDivStyle}>
      <div style={styles.headerLogoStyle}>
        <Logo />
        <div style={styles.user.wrapper}>
          <UserOutlined style={{ fontSize: '18px'}} />
          <p style={styles.user.fullName}>{userFullName}</p>
        </div>
      </div>
    </div>
  )
}

export default Header

const styles = {
  headerDivStyle:{
    zIndex:"1000",
    width:'100%',
    position: "fixed",
    top: 0,
    backgroundColor:"white",
    boxShadow: "0 3px 5px rgba(57, 63, 72, 0.3)"
  },
  headerLogoStyle: {
    display: "flex",
    margin: "0px 1rem",
    paddingBottom: "4px",
    height: "4rem",
    justifyContent: "space-between",
    width: "100%",
    alignContent: "center",
    alignItems: "center"
  },
  user:{
    wrapper:{
      display: "flex",
      alignItems: "center"
    },
    fullName:{ margin: "0px 2rem 0px 8px", fontSize: "18px" }
  }
}