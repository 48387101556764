import React,{useState,Dispatch,SetStateAction} from 'react'
import {IQuestion} from '../../../types'
import { TextField, MaskedTextField } from '@fluentui/react/lib/TextField';
import { Stack, IStackProps, IStackStyles } from '@fluentui/react/lib/Stack';
import {styles} from '../../styles'
interface IProps {
    question:IQuestion
    setNewQuestion:(title: string, newQuestion: IQuestion) => void
    title:string
}
const stackStyles: Partial<IStackStyles> = { root: { width: 645} };

const TextMultilineQuestion = (props:IProps):JSX.Element => {

    const {question, setNewQuestion, title} = props   

    return (
        <TextField  
            multiline
            // style={{maxWidth:"70rem"}}
            onChange={(event,text) =>setNewQuestion(title, {...question, Response:text})}
        />
    )
}

export default TextMultilineQuestion