import React,{useState,Dispatch,SetStateAction} from 'react'
import QuestionGrid from './questionDataGrid'
import AddProjectPanel from '../../newProjectPanel';
import {IPanelData,IProduct,IQuestion,ISurvey, ISurveyData} from '../../../types'
import mockData from '../../../mockdata.json'
import updatedMockData from '../../../updatedMockData.json'

interface IProps {
    question:IQuestion
    setNewQuestion:(title: string, newQuestion: IQuestion) => void
    title:string
}

const ProductGridQuestion = (props:IProps):JSX.Element => {

    const initialPanelData:IPanelData = {isOpen: false, question:{Id:"",SubTitle:"",Title:"", Type:""}}
    const [panelData, setPanelData] = useState<IPanelData>(initialPanelData)
    const {question, setNewQuestion, title} = props   

    const addProductResponseQuestion = (product:IProduct) => {
        let ProductResponses = question.ProductResponses
        if(ProductResponses && ProductResponses?.length > 0){
            ProductResponses.push(product)
        }else{
            ProductResponses = [product]
        }
        const newQuestions = {...question, ProductResponses}
        setNewQuestion(title, newQuestions)
    }

    const updateProductResponseQuestion = (updatedProduct:IProduct) => {
        let newProductResponses = question.ProductResponses?.map(product =>{
            if(product.id === updatedProduct.id){
                return updatedProduct
            }
            return product
        })
        const newQuestions = ({...question, ProductResponses:newProductResponses})
        setNewQuestion(title, newQuestions)
    }

    const addFilesToQuestion = (Files:File[]) => {
        console.log('Files: ', Files);
        const newQuestions = ({...question, Files})
        setNewQuestion(title, newQuestions)
    }

    const deleteProductFromQuestion = (productIndex:number) => {
        let newProductRespose = question.ProductResponses?.filter((item, index) => {
            return index === productIndex ? false : true
        })
        const newQuestions = ({...question, ProductResponses:newProductRespose})
        setNewQuestion(title, newQuestions)
    }


    return (
        <div>
            <AddProjectPanel 
                panelData={{...panelData}}
                setPanelData={setPanelData}
                addProductResponseQuestion={addProductResponseQuestion}
                updateProductResponseQuestion={updateProductResponseQuestion}
                title={title}
            />
            <QuestionGrid 
                key={question.Id}
                panelData={{...panelData}}
                setPanelData={setPanelData}
                question={question}
                addFilesToQuestion={addFilesToQuestion}
                deleteProductFromQuestion={deleteProductFromQuestion}
            />
        </div>
    )
}

export default ProductGridQuestion