import React from 'react';
import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";
import './App.css';
import ChemicalSurvey from './chemical-survey';
import { initializeIcons } from '@fluentui/react/lib/Icons';

function App() {
  initializeIcons()


  return (
    <div className="App" style={styles.appDivStyle}>
      <ChemicalSurvey />
    </div>
  );
}

export default App;

const styles = {
  iframe : {
    height: "50rem",
    width: "20rem"
  }, 
  surveyDiv : {
    display: "flex",
  },
  tableStyle: {
    margin: "auto",
    marginTop: "5rem"
  },
  appDivStyle:{
    backgroundColor:"whitesmoke",
    // paddingBottom:"5rem"
  }
}
